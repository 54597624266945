<template>
  <div id="app">
    <nav>
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <!-- <router-link :to="`/index?path=${path}`" class="home">跳转</router-link> -->
    </nav>

    <router-view />
  </div>
</template>
<script>
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    // 这里存放数据
    return {
      path: '2022113009001',
    }
  },
  // 计算属性类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      console.log(reg)
      var r = window.location.search.substr(1).match(reg)
      console.log(r)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
  },
  // 生命周期 - 创建完成(可以访问当前this实例)

  created() {
    // 获取？方式传递的参数
    // this.id = this.$route.query.id
  },
  // 生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    let navToPath = this.getQueryString('path')

    console.info('----------->', navToPath)
    if (navToPath) {
      this.$router.push({ path: navToPath })
    }
  },
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有keep-alive缓存功能,这个函数会触发
  activated() {},
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f5f5;
  // background: pink;
  // width: 100vw;
  // height: 100vh;
}
</style>
