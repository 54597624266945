import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/tec-report/:id',
    name: 'recordDate',
    component: () => import('../views/recordDate.vue')
  },
  {
    path: '/tenant-report/:tenantId',
    name: 'tenant-report',
    component: () => import('../views/tenant-report.vue')
  },
  {
    path: '/partnerData',
    name: 'partnerData',
    component: () => import('../views/partnerData.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
