import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'

import 'vant/lib/index.css';

import {
  Lazyload, Calendar, Cell, CellGroup,
  Image as VanImage, Empty, Toast, Divider, Icon
} from 'vant';



Vue.use(Icon);
Vue.use(Divider);
Vue.use(Toast);

Vue.use(VanImage);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Calendar)
Vue.use(Empty);
Vue.use(Lazyload);

Vue.use(Lazyload, {
  lazyComponent: true,
});



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
